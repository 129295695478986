import React from "react";

const ArrowRight = () => {
  return (
    <svg
      width="39"
      height="14"
      viewBox="0 0 39 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M36.2017 6.25402C36.0452 6.05874 35.9277 5.98062 35.8495 5.86345C34.4014 4.41834 32.9925 3.01228 31.5444 1.56717C31.427 1.45 31.3487 1.37188 31.2704 1.25471C31.0356 0.942252 31.0748 0.512625 31.3487 0.239225C31.6227 -0.0341752 32.0532 -0.073232 32.3663 0.122054C32.4837 0.200168 32.562 0.31734 32.6794 0.395454C34.6754 2.38737 36.6714 4.37928 38.7065 6.37119C39.0978 6.76177 39.0978 7.23045 38.7065 7.62102C36.7105 9.61294 34.7145 11.6048 32.7185 13.5577C32.2097 14.0654 31.7401 14.1436 31.3487 13.753C30.9965 13.3624 31.0356 12.9328 31.5835 12.386C32.9925 10.9799 34.4405 9.53482 35.8495 8.12877C35.9669 8.01159 36.0452 7.93348 36.1626 7.85536C36.1626 7.81631 36.1234 7.77725 36.1234 7.73819C35.9669 7.73819 35.8103 7.73819 35.6538 7.73819C24.1867 7.85537 12.7195 7.85536 1.25238 7.85536C1.0567 7.85536 0.861014 7.85537 0.665329 7.81631C0.273959 7.77725 0 7.42574 0 7.03517C0 6.64459 0.273959 6.33214 0.665329 6.25402C0.861014 6.21497 1.0567 6.21497 1.25238 6.21497C12.7195 6.21497 24.1475 6.21497 35.6147 6.21497C35.7712 6.25402 35.9277 6.25402 36.2017 6.25402Z"
        fill="#582734"
      />
    </svg>
  );
};

export default ArrowRight;
