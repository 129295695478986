import React from "react";

const CircleBot = () => {
  return (
    <svg
      width="440"
      height="715"
      viewBox="0 0 440 715"
      fill="none"
      className={"CircleBot"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M660.425 27.1397C679.7 45.4928 689.333 72.2073 690.193 104.872C691.053 137.539 683.135 176.121 667.347 218.134C635.772 302.16 572.756 399.819 485.769 491.176C398.781 582.533 304.325 650.256 221.947 685.908C180.757 703.734 142.61 713.532 109.939 714.273C77.2714 715.013 50.1172 706.7 30.8423 688.347C11.5673 669.994 1.93411 643.279 1.0743 610.615C0.214396 577.947 8.13236 539.366 23.92 497.352C55.4945 413.327 118.511 315.668 205.498 224.31C292.486 132.953 386.942 65.2305 469.32 29.579C510.51 11.7528 548.657 1.95498 581.328 1.21409C613.995 0.47325 641.15 8.78662 660.425 27.1397Z"
        stroke="#C48697"
      />
    </svg>
  );
};

export default CircleBot;
