import React from "react";

const Logo = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      className={"Logo"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_185_1026)">
        <path
          d="M33.4396 6.56007H14.3196C12.9996 6.56007 11.6796 6.84007 10.5196 7.44007C8.67963 8.40007 6.59963 10.3601 6.59963 14.4001C6.59963 17.8801 8.35963 19.6801 10.1596 20.6001C11.9596 21.5201 14.0396 21.6401 15.9996 21.0801L23.9596 18.8001C25.7996 18.2801 27.7596 18.3601 29.4796 19.2001C31.4796 20.1601 33.4396 22.1201 33.4396 26.2801C33.4396 33.8801 26.8796 33.3601 26.8796 33.3601H6.55963C6.55963 33.3601 6.15963 25.8401 12.5596 25.8401C15.7996 25.8401 17.5996 28.2401 17.5996 28.2401L25.4796 38.3201C25.9996 39.0001 26.7196 39.5601 27.5596 39.8401C28.5596 40.1601 30.0796 40.2801 31.4796 39.2001C32.8796 38.1201 32.8396 36.6801 32.5196 35.6801C32.3196 35.0401 31.9996 34.4801 31.5996 34.0001L9.03963 6.40007C8.11963 5.28007 7.75963 3.80007 8.19963 2.40007C8.39963 1.80007 8.71963 1.24007 9.23963 0.800071C9.91963 0.240071 10.6796 0.0800715 11.3596 0.120071C12.5596 0.160071 13.6396 0.760071 14.3996 1.68007L22.1196 11.1201C22.1196 11.1201 24.1596 14.3601 27.6396 14.3601C33.4396 14.2001 33.4396 6.56007 33.4396 6.56007Z"
          fill="#582734"
        />
      </g>
      <defs>
        <clipPath id="clip0_185_1026">
          <rect width="40" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;
