const decryptComphort = {
  "1": [
    ""
  ],
  "2": [
    ""
  ],
  "3": [
    "Ваши отношения-это крепкие узы брака и привязанность. Теплые отношения, стремление к совместному творчеству или созданию проекту. Совместный акцент на детях, вся крепость отношений завязана на детях.",
    "В отношениях могут присутствовать: ревность, капризы, скупость, чрезмерную требовательность и принижение, если статус одного из партнеров ниже другого.",
    "Вам важно в отношениях: совместно поддерживать стиль, посещать красивые рестораны. Женщина в паре должна не бояться говорить о своих желаниях. Какая женщина в паре такая и энергия будет царить в них. Стремитесь иметь заработок как каждый отдельно, так и совместный."
  ],
  "4": [
    "Ваши отношения- это уважение, доверие, тотальная дружба, стабильность, принципы. Союз двух лидеров, несущих ответственность. Мужчина в отношениях главнее.",
    "В отношениях могут присутствовать: придирки, скупое проявление чувств, абьюз, борьбу за лидерство.",
    "Вам важно в отношениях: распределять управление совместными делами, распределять ответственность, помогать друг другу в быту, делегировать и уметь позволять это делать. Сохранять баланс между реализацией и отношениями, нельзя соприкасать отношения с реализацией."
  ],
  "5": [
    "Ваши отношения- это доверие и верность, крепкий фундамент традиций и правил, которые нельзя нарушать. Интеллектуальные беседы и обмен знаниями.",
    "В отношениях могут присутствовать: когда Вы поучаете друг друга, соревнуетесь в знаниях и умениях. Навязывание своего мнения, жестокость. Не толкать партнёра в действия.",
    "Вам важно в отношениях: пополнять фотоальбом каждый год, расширять кругозор совместно. Уважать отца партнера каждому в паре. Женщине важно не воспринимать мужчину –отцом(папой)"
  ],
  "6": [
    "Ваши отношения- это взаимная любовь, честность, искренность.",
    "В отношениях могут присутствовать: любые манипуляции и увиливания- крах для отношений. Стремиться идти к красивым отношениям с регулярной романтикой, если этого нет, то отношения обречены на придирки, скидывание ответственности, капризы, скупость на чувства или со зависимость.",
    "Вам важно в отношениях: много говорить, общаться на языке любви, соприкасаться в интиме и открытости. Дарить подарки исходя из желаний партнера, говорить комплименты, создать атмосферу любви и красоты.",
    "Отношения могут быть как урок на открытую близкую и безусловную любовь."
  ],
  "7": [
    "Ваши отношения- это активность на достижения. Один из пары чуть сильнее духом. Вы встретились, чтобы подтянуть друг друга и вместе достичь цели. В отношениях много поддержки, мотивации результатами, восхищения результатами друг друга или совместное активное хобби.",
    "В отношениях могут присутствовать: конфликты, соперничество, отсутствие поддержки. Разрушающие конфликты на ровном месте. Один из партнеров может заставлять другого делать желаемое.",
    "Важно в отношениях: друг друга мотивировать, как к достижению личных результатов, так и к достижению любых совместных планов. Всегда поддерживать и ценить желания каждого, при этом не давить на партнёра и не заставлять делать, то что нужно только Вам. Не винить в отсутствии своих достижений Вашего партнёра."
  ],
  "8": [
    "Ваши отношения- это честность, соблюдение всех договоренностей.",
    "В отношениях могут присутствовать: гипер- честность, ложь, суды, осуждения, манипуляции и навязывание чего-либо.",
    "Важно в отношениях: официально договариваться о построении отношений, а также совместная успешная реализация. Идти вместе в проработку по духовности и обсуждать это.",
    "Важно, чтобы каждый понимал, что вы с партнером разные и это нормально. Возможно, что один из партнеров находится в детской позиций и это требуется проработать."
  ],
  "9": [
    "Ваши отношения- это передача друг другу знаний. Уважение личных границ каждого во всех проявлениях. Один из партнеров занимает позицию наставника.",
    "В отношениях могут присутствовать: эгоизм, отсутствие мудрости в финансовом плане, разница в личностном росте, отторжение секса, одиночество друг с другом. Склонны узко воспринимать только свой мир- это нужно прорабатывать.",
    "Важно в отношениях: определиться каждому, что для них материальный комфорт. Гармония и что-то общее, что взращено совместно из мудрости. Принимать знания партнера, которые он передает, находить в этом интерес."
  ],
  "10": [
    "Ваши отношения- это ощущение радости от партнера. Совместное дурачество, весело друг с другом, благодарность и доверие.",
    "В отношениях могут присутствовать: борьба за превосходство, один из партнеров может проваливаться по финансам. Упрёки по финансам, отношения страдают в целом от отсутствия денег.",
    "В отношениях важно: научиться легко реагировать на любые предложения партнёра (даже внезапные), не вешать ярлыки «должен/на», больше путешествовать по миру, наполнять отношения идеями.",
  ],
  "11": [
    "Ваши отношения- это чувственность, любовь к сексу, совместный спорт, активный отдых, пробовать новое.",
    "В отношениях могут присутствовать: сумасшествие, тиранство, шантаж, трудоголизм, когда нет времени друг на друга.",
    "В отношениях важно: вместе проводить время активно и для этого учиться делегировать. Быть готовыми к любым экспериментам, в том числе в сексуальных предпочтениях друг друга. Любая физическая активность всегда будет на пользу."
  ],
  "12": [
    "Ваши отношения- это Поддержка друг друга, вместе «пострадать», творческая активность, успешная реализация.",
    "В отношениях могут присутствовать: зависимое положение партнера, чувства потери себя и личная неудовлетворенность, употребление разных допингов. Жертвенность ради интересов партнера.",
    "В отношениях важно: узнать в чем каждый талантлив и идти в эту реализацию. Вы как пара интересные, оригинальные, хорошая совместная креативность, особенно в поднятии настроения. Не нарушать личные границы, создавать что-то вместе необычное, заниматься волонтерством или благотворительностью, много разговаривать, если сложности в отношениях, а их так хочется сохранить, то не бояться посещать вместе психологов."
  ],
  "13": [
    "Ваши отношения- это постоянные совместные изменения, смена места жительства от квартир до городов и стран, путешествия. Крепкая поддержка в независимости от обстановки, сила поддержки в любых начинаниях.",
    "В отношениях могут присутствовать: манипуляции, захламленность, страх перемен. Невозможность диалога, страх отпускать друг друга. Склонны то расходиться, то вновь воссоединяться.",
    "В отношениях важно: расхламление, экологичный всплеск эмоций и адреналина. Уходить от страха потери друг друга. Больше разговаривать о росте через трудности. Не держаться за то, что уже «отжило». Делиться страхами и помогать друг другу в них идти."
  ],
  "14": [
    "Ваши отношения- это гармония, доверие, взаимопонимание, согласие. Готовность прийти на помощь в любой момент и поддержать как друг. Совместная работа в творчестве, с детьми.",
    "В отношениях могут присутствовать: не достойное поведение, агрессия, частые обиды и ситуации без желания к примирению, использование партнера заведомо зная, что всё либо сойдет с рук, либо решится само собой, манипуляции.",
    "Важно в отношениях: научиться говорить о конфликтах, выдыхать, налаживать диалог и искать компромисс. Научить говорить и разговаривать, а не относиться к конфликтам легкомысленно."
  ],
  "15": [
    "Ваши отношения- это однозначно зеркальные отношения. Совместное стремление к жизни и совместный рост. Страстные отношения, страстный секс с экспериментами.",
    "В отношениях могут присутствовать: унижения, издевательства, ненависть, привязки, властвование, любовные интриги. Манипуляции, выбор без выбора, унижения друг друга, манипуляции сексом и деньгами, контроль и собственничество. Качество отношений зависит от финансового положения. Завязки на еде, алкоголе и т.д., как следствие не выраженных эмоций друг к другу.",
    "В отношениях важно: сплочение через вкусовые рецепторы, быть «вкусными» друг для друга. Удовлетворять совместные материальные потребности и желания, привносить страсть в быт и постель. Рекомендуется одному в паре реализоваться через деньги и сексуальность."
  ],
  "16": [
    "Ваши отношения- это мудрость, духовная близость и поддержка. Желание выстраивать отношения и смелое начинание делать всё с нуля.",
    "В отношениях могут присутствовать: конфликты на пустом месте, умышленное причинение боли и страдания партнеру. Причинение душевной боли ради собственного удовлетворения. Свойственно меняться этой ролью.",
    "В отношениях важно: стараться чаще обсуждать изменения в жизни друг друга, совместно посещать ретриты, духовные практики, что-то изучать. Пробовать создавать совместные проекты, деятельность. Научиться прощать и отпускать обиды. Рекомендуется идти в духовные проработки. "
  ],
  "17": [
    "Ваши отношения- это обмен идеями, мыслями, поддержка, желание совместно рисковать, чтобы пойти в новое. Любовь, в которой много дружбы. Отношения часто начинаются из интереса дружбы.",
    "В отношениях могут присутствовать: эмоциональная дистанция, холод в отношениях, гордыня, предательство, критика, завышенные требования к партнёру, как пара склонны к чрезмерной гордости и позиционированию себя выше других.",
    "Важно в отношениях: много обниматься, проводить время совместно в различных СПА, массажах и т.д. Много говорить о чувствах и проявлять в обществе. Делать совместные фотосессии, показывать красоту пары людям. Совместная красота, легкость, действия. Красивая картина отношений. "
  ],
  "18": [
    "Ваши отношения- это романтичные взаимоотношения и забота, чувственно-сексуальные отношения, легкость в воплощении и реализации совместно задуманного. Ваша встреча произошла для сплочения, так как в одиночку страшно.",
    "В отношениях могут присутствовать: отсутствие уверенности и твердой почвы под ногами. Отсутствие постоянной основы от материи до мыслей.  Непостоянство, странные отношения. Отсутствие гармонии в отношениях за счет тайн, сплетен и изматывающая ревность, которая может быть выдуманной.",
    "В отношениях важно: прийти к романтичности, смотреть на луну и звезды. Прийти к чувственности, много тактильных ощущений между собой. Вести открытые разговоры, избавиться от всевозможных тайн. Совместно изучать темы мироздания, что-то разгадывать и даже загадывать друг другу загадки. Обсуждать страхи и потихоньку в них идти."
  ],
  "19": [
    "Ваши отношения- это изобилие и благополучие у всех на виду. Успешная социальная реализация каждого партнера отдельно, а также совместное времяпрепровождение, благотворительность, щедрость.",
    "В отношениях могут присутствовать: ссоры, конфликты за лидерство в паре и из-за разных потребностей и ощущений, скупость и жадность по отношению к партнеру и к себе, звездность и внимание.",
    "В отношениях важно: при совместных идеях реализации ставить цель на помощь чему-то или кому-то, а не материальную ценность. Хорошо сплочают дети: совместно времяпрепровождение и игры. Научить периодически самим быть детьми и играть. Одаривать друг друга вниманием, комплиментами и подарками."
  ],
  "20": [
    "Ваши отношения- это единение душ и родство, комфорт быть друг с другом. Умение выстраивать свою семью как систему, с новыми умыслами и правилами.",
    "В отношениях могут присутствовать: ссоры из-за разлада в роду друг друга, из-за нежелания иметь детей, из-за плохого отношения партнеров к родственникам. Натянутые отношения внутри из-за доказательства роду о том, что в вашей семье всё правильно и хорошо.",
    "В отношениях важно: хотеть много детей, уважать семьи друг друга. Чувствовать себя одной общей семьёй и иметь официально зарегистрированные отношения. Праздновать семейные праздники, наладить иерархию. Быть благодарным родовым системам."
  ],
  "21": [
    "Ваши отношения- это открытость и миролюбие. Желание одного из пары показать другому многообразие мира.",
    "Соблюдение личных границ, доверие и поддержка.",
    "В отношениях могут присутствовать: навязывание своих условий, ограничений, конфликты из-за разного мировоззрения. Разочарование в партнёре и отдаление друг от друга из-за не сбывшихся ожиданий.",
    " В отношениях важно: уважать многогранность друг друга. Обсуждать границы отношений, что в них допустимо, а что нет и совместно их расширять. Гармония складывается за счёт переездов обучений и путешествий, периодически задаваться внутри пары вопросом: «что возможно ещё расширить» выходить за рамки «закрытого» партнера (один открытый в паре). Идеально прийти к свободе без границ."
  ],
  "22": [
    "Ваши отношения- это лёгкость, много радости, оптимизм, романтика, свобода в отношениях.",
    "В отношениях могут присутствовать: перекладывание ответственности друг на друга, не желание строить серьёзные отношения. Безответственные «свободные» отношения чаще из-за одного партнера, который сам в себе не разобрался, измены, торопятся жить.",
    "В отношениях важно: подходить к решению вопросов через лёгкость и творчество. Совместно реализовать даже абсурдные идеи. Гармония отношений через то, что выходит за рамки норм."
  ],
}

export default decryptComphort