const decryptFinance = {
  "1": [
    ""
  ],
  "2": [
    ""
  ],
  "3": [
    "Уход денег через роскошь, которая приобретается для того что бы выделяться и казаться ярким среди людей. Не осознанные траты. Перевод денег на ненужную роскошь(бренды). Кредиты ради чего-то нового, необдуманного и не особо нужного, то есть расходы не по доходу и возможностям. Все траты что «не по силам», но кажется, что эта вещь принесет какую-то визуальный «ложный» статус в обществе."
  ],
  "4": [
    "Деньги уходят от чрезмерного контроля над ними, в данном случае нужно исключить «домашнюю бухгалтерию», записывать и контролировать каждый потраченный рубль. Всё потраченное на мнимое ощущение «статустности» так же приводит к их потере. Траты в отношениях, что указывает на состояние «выслужиться» и «подкупить» или «купить»"
  ],
  "5": [
    "Жадность на знания, когда Вы покупаете уже не первый курс или обучение, но так и не пришли к действию, а просто собираете эту «библиотеку» новых знаний. Поиск и собирание выгодных и экономных решений или покупок, не копите скидочные карты и купоны. Так же присуще терять деньги из чувства жадности трат на себя и траты только на дом и семью. Нельзя себя обделять. Все полученные знания важно отдавать или передавать дальше. И все обучения должны быть в Вашей жизни по ощущениям, а не из важности статуса этого обучения. Все полученные знания и новые обучения должны идти на расширение вашего ума и деятельности, то есть улучшать качество, а не брать количеством."
  ],
  "6": [
    "Вы можете сталкиваться со сложностью выбора: что купить? Надо ли вообще это покупать? И даже если куплено, то терзают муки- правильно ли я сделал/ла? ! То есть Вам присуще сомнения на тратах и покупках, что очень пагубно влияет на их существование в жизни. Деньги так же уходят на стильные вещи, на постоянное улучшение внешности и нередко много уходит на отношения (в отношения)- но если это через призму «хочу нравиться», то это тормозит денежный поток."
  ],
  "7": [
    "Хорошие вложения денег – в движение (н.р. в путешествия, машины). Важно так же вкладывать в команду, в развитие и своё дело, но только из состояния, сделать лучше, помочь. Если эти вложения ради того, чтобы показаться круче кого-то и завоевать титул, то деньги будут стопориться. "
  ],
  "8": [
    "Денег нет, если нет баланса между вашим духовным и материальным, если вложения идут в разбирательства (суды и прочее). Так же любое осуждение кого-то, кто, например, якобы Вас лучше, но делает что-то неправильно, как вам кажется- тоже тормозит и даёт мощные откаты в денежной сфере. Вам важно научиться жить в мире и согласии, в положенные сроки платить налоги и всегда работать официально, не скрывая свои большие доходы, а также работа по совести, работа в судебных разбирательствах."
  ],
  "9": [
    "Вы склонны экономить на себе и совсем не вкладывать в себя и своё развитие и это первый шаг к безденежью. Нельзя уходить от людей и говорить «мне ничего не надо!» Вам важно вкладывать в те знания, которые являются «тайными», о которых не известно большому кругу людей, в идеале научиться продавать свои знания."
  ],
  "10": [
    "Утекают по воле случая, через импульсивные траты, словно «деньги на ветер». Категорически нельзя тратить на азартные игры. Рекомендуется делать вложения при создании команды, платить людям «единомышленникам» и тратить на «подкупание» людей."
  ],
  "11": [
    "«Раздвоенность», склонны к импульсивным тратам. Нельзя скупать то, что фиктивно помогает выглядеть телу лучше. Больше вкладывать в своё тело через занятия спортом, на поддержание тонуса и жизненной силы. Хорошо тратить и вкладываться вто, что связывает Вас с природой, например, всевозможные тур. Походы. Идеально если Вы будете сами создавать такие движения и поведете за собой людей, показывая им тем самым как необходимо соединять тело и душу. "
  ],
  "12": [
    "Деньги уходят, когда Вы тратите на других людей. Нельзя что бы кто-то жил за Ваш счет. Хорошо заниматься благотворительностью или хотя бы частично там быть. Ваша задача если вы тратите, то это было с мотивацией."
  ],
  "13": [
    "Деньги уходят, когда Вы их вкладываете в изменение своей внешности (операции, бьюти мини-операции, уколы красоты и прочее), а также всё что связано с неоправданным риском. Идеально помогать другим выбираться из денежных проблем и в первую очередь помочь в этом себе. Идеально заниматься тем, что приносит людям трансформацию (например, психология, как трансформация души, массаж- как улучшение и трансформация тела). А вот себя нужно улучшать, только познавая духовное. "
  ],
  "14": [
    "Склонны к эмоциональным тратам, так же тормоз Ваших денег- любая финансовая зависимость (от кого-то или чего-то).  Больше отдавайте туда, где вы растете как творческая личность, на вкусную еду и развитие духовного таланта."
  ],
  "15": [
    "Яркая зависимость от всего, что можно назвать материальным благом. Нельзя тратить на «допинги» и всякую «грязь» - алкоголь, тусовки, наркотики, крутые тачки и так далее. И уходят так же если вкладываете в ложную веру в себя, когда ожидания превышают возможности. Так же все вложения должны быть только тогда, когда вы осознанно готовы с этими деньгами расстаться. Здесь самое важное познать свою сексуальность и тогда вы попросту перестанете её «покупать». Так же деньги не идут, если всё что вы делаете для заработка, так или иначе делается через агрессию."
  ],
  "16": [
    "Деньги могут уходить на постоянное духовное взращивание и поддержание психического состояния и тут важно не заигрываться! Обращайтесь только к осознанным специалистам и имейте меру.  "
  ],
  "17": [
    "Не стоит спускать много денег на имидж и продвижение, чтобы стать слишком узнаваемым и привлекать за счёт этой яркости. Так же если вы требуете от кого-то что он вам должен или должны- это тормозит сильно денежный поток. Постарайтесь возрастить по истине классный личный бренд, без пустых фантиков и пускания пыли в глаза. Идеально развить это в себе и вещать на массы людей. "
  ],
  "18": [
    "Много зарабатывать мешает огромное кол-во страхов и нужно просто в них пойти! Страхи прорабатывать нужно, но не стоит уходить слишком глубоко в эзотерику и делать дымовую завесу дома из благовоний). Научитесь совмещать эзотерику и материю."
  ],
  "19": [
    "Вы склонны во взрослом возрасте перекрывать те «хочу», что очень хотелось в детстве и это пагубно влияет на денежный поток. Важно уйти от детской позиции и оставить детские желания в прошлом! Если очень хочется покупайте, но без фанатизма. Соберите ту самую коллекцию машинок, но постепенно и без жадности). Идеально связать свою деятельность с детьми или хотя бы вкладывать в благотворительность, что связана с детьми."
  ],
  "20": [
    "Нельзя не осознанно и постоянно вкладывать в своих родителей, как постоянная помощь, не стоит им доказывать, что вы всё можете и выслуживаться перед ними. Лучше если эти же деньги пойдут, например, на подарок племяннику, который так давно мечтает о той самой игрушке! Старайтесь тратить на то, чтобы получать информацию (в инфо поле) и на проработку родовых травм. "
  ],
  "21": [
    "Если Вы не идете в ногу со временем, не эволюционируете и не современны- это тормозит денежный поток. Так же важно не ограничивать своих родных(семью) от внешнего мира, в том числе ставить запреты банально на соц. Сети и на всё то, что связывает их с нынешним миром. Тратить лучше на то что бы выводить себя постоянно на новый уровень жизни, на всевозможные поездки, посещать разные страны, на новые обучения и находить новые знакомства. "
  ],
  "22": [
    "Блокируют деньги ограничения самого себя или если вас ограничивают. Всевозможные рамки в деятельности, в деньгах, в сфере реализации. Хорошо связывать себя с тем, что не имеет рамок и ограничений ни в плане развития, ни в плане рамок графика или территориальной привязки. Понять, что Деньги не равно свобода! Склонны тратить на мнимую свободу, как бы покупая её. "
  ],
}

export default decryptFinance