import React from "react";

const ArrowLeft = () => {
  return (
    <svg
      width="39"
      height="14"
      viewBox="0 0 39 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.79829 7.74598C2.95484 7.94127 3.07225 8.01938 3.15052 8.13655C4.59859 9.58166 6.00753 10.9877 7.45559 12.4328C7.573 12.55 7.65128 12.6281 7.72955 12.7453C7.96437 13.0578 7.92524 13.4874 7.65128 13.7608C7.37732 14.0342 6.94681 14.0732 6.63372 13.8779C6.5163 13.7998 6.43803 13.6827 6.32062 13.6045C4.32463 11.6126 2.32865 9.62072 0.293525 7.62881C-0.0978438 7.23824 -0.0978438 6.76955 0.293525 6.37898C2.28951 4.38707 4.28549 2.39515 6.28148 0.442298C6.79026 -0.065445 7.25991 -0.14356 7.65128 0.247012C8.00351 0.637584 7.96437 1.06721 7.41646 1.61401C6.00753 3.02007 4.55946 4.46518 3.15052 5.87124C3.03311 5.98841 2.95484 6.06652 2.83743 6.14464C2.83743 6.1837 2.87657 6.22275 2.87657 6.26181C3.03311 6.26181 3.18966 6.26181 3.34621 6.26181C14.8133 6.14464 26.2805 6.14464 37.7476 6.14464C37.9433 6.14464 38.139 6.14463 38.3347 6.18369C38.726 6.22275 39 6.57426 39 6.96483C39 7.35541 38.726 7.66786 38.3347 7.74598C38.139 7.78503 37.9433 7.78503 37.7476 7.78503C26.2805 7.78504 14.8525 7.78504 3.38535 7.78504C3.2288 7.74598 3.07225 7.74598 2.79829 7.74598Z"
        fill="white"
      />
    </svg>
  );
};

export default ArrowLeft;
