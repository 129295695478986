const decryptPeriods = {
  "1": [
    "В плюсе этот период — это достижение целей, вы можете явно стать лидером и показать пример окружающим.  И именно сейчас к вам могут приходить гениальные идеи и эти идеи будут признаны, тем самым можете дать очень много пользы. Нет страхов что-то создавать и показывать это другим, потому что это будет по-настоящему что-то стоящее. В целом именно сейчас нет боязни перед предстоящим будущим, а также имеете полную ясность и определённость в жизни.",
    "А вот минус периода — это непонимание кто вы в этом мире. Нет любимого дела и уж тем более реализации с помощью его. Склонны быть чрезмерно обидчивыми и не редко испытываете жадность.",
    "Отсутствует Ваша проявленность и вся жизнь, все идеи, даже если они есть, то они попросту складируются «в стол», словно всю жизнь отложить на потом. Так же и позиция занятая Вами будет отражать отсутствие стремления взять на себя какую-либо ответственность и пытаться «отсидеться в сторонке», словно занять вторую ступень пьедестала, когда есть весь потенциал быть на первой ступени. В этот период склонны испытывать много страхов, быть не приметным и часто во многом себя ограничивать"
  ],
  "2": [
    "Плюс периода принесёт Вам много веры в себя и в свои силы. Полный контроль над эмоциями и отсутствие перепадов настроения. Принимаете то, что существуют высшие силы и что они помогают Вам. В этот период особенно хорошо развита интуиция и «чуйка» на события. В конфликтах всегда выступаете не зависимой стороной и дипломатично можете их решать. Именно сейчас у Вас могут быть хорошие взаимоотношения с бабушками и дедушками, и мы бы рекомендовали на это обратить внимание, то есть если ранее было какое-то недопонимание или обиды, то самое время это исправить. В целом период принесёт стабильное эмоциональное состояние и в любых ситуация Вы будете иметь спокойный ум и умение выдержать нейтралитет.",
    "В минусе же период проходит в страхах перед неизвестностью, с отрицанием и недоверием к высшим силам. Именно сейчас огромная тяга к сплетням, распространению слухов и не проверенной информации. Часто можете провоцировать конфликтные ситуации, а Ваша позиция — это как двойственное поведение, где много вранья, одетых масок и отсутствие стабильности в своих высказываниях и убеждениях- это всё характерно человеку, который хочет показаться мнимо хорошим, а в душе это совсем не так. Отсутствие веры в себя и в других, склонность к скрытности и даже отшельничеству."
  ],
  "3": [
    "В ПЛЮСЕ этот период может принести раскрытие Вашей женской роли, для женщин это может означать встречу любимого, свой собственный бизнес, большое окружение женщин (большой прилив женской энергии).  В это время Вы может выйти замуж, стать матерью, быть хозяйкой, обрести свой дом, хорошо зарабатывать, становится очень красивой и привлекательной, можете обрести высокий статус и положение в обществе, можете выигрывать конкурсы красоты и т.д.",
    "В МИНУСЕ жизнь в этот период протекает так, что вы теряете женскую энергию. И всё будет указывать на то, что этой женской энергии не хватает и тогда могут рушатся отношения, приходит состояние одиночества, не получаете поддержки от мужчин, так же можете не уважать или бояться мужчин в это время. В целом не принимать женственность, можете слишком много тянуть на себе, пытаться стать «мужчиной». Становиться давящей, контролирующей, истеричной, требовательной, униженной, иметь с самооценкой большие проблемы, вести себя не достойно, не сохранять самообладание и свои манеры. Может проявляться бесплодность или проблемы с детьми, как не способность проявить себя как мать и хозяйка. Могут срываться свадьбы в это время."
  ],
  "4": [
    "Плюс для женщины в этот период жизни, может выйти замуж, но если по каким-то обстоятельствам замужество не случается, то чувствовать покровительство мужчины. Бывает так, что энергия этого периода влияет на отношения и они переходят в разряд «деловых отношений», тогда женщина встречает свой идеал мужчины - это образ её начальника. Так тоже может быть и тогда он может принести ей продвижение по службе, перспективы. Могут материализоваться желания и то, что она долго хотела, наконец-то воплотится – это очень хороший аспект.",
    "А вот в минусе этот период для женщины значит, что мужчина может уходить, либо очень часто мужчина встречается, но взять ответственность за неё он не может: этот мужчина либо слабый, либо женат, либо он имеет высокий пост, при котором он не может афишировать отношения.",
    "Женщина в этот период может стать любовницей, женщиной, которая тянет на себе мужчину. Может столкнуться с тем что мужчина уйдет он нее, что мужчина ей изменяет, либо у ее мужчины какой-то кризис.",
    "Может быть мужчина рядом с ней который перестанет зарабатывать.",
    "Основная суть периода в минусе у женщины по периоду в том, что женщина не чувствует поддержу мужчины в это время. Она может получить удары судьбы от мужчины или больные уроки.",
    "Женщине в этот период нужно проработать отношения с папой и принять себя как женщину, простить папу, не держать обид, проработать себя в этом плане: начать уважать мужчин, принимать их мужское начало, дать им возможность решать, как они решают. Подчиниться мужчине, научиться понимать свою женскую природу. Победой будет, если женщина в этот период примет мужчину и вдохновит его на ответственность.",
    "Для мужчины этот период в плюсе –  означает то, что мужчина может очень сильно укрепиться в это время: стать отцом, мужем, стать начальником, повыситься в должности, начать зарабатывать больше денег, проявить себя как мужчина и, конечно, для мужчины с четверкой по периоду обязательно нужно взять на себя ответственность.",
    "А вот в минусе по периоду у мужчины зачастую ощущение собственной слабости, что он не может позаботится, он не справляется со своими мужскими обязанностями, он не сможет создать семью в это время, рожать детей, купить квартиру, машину, материально обеспечить своих близких, воплотить свои идеи в реальность.\n" +
    "Могут быть проблемы с начальством, поэтому им нужно взять на себя ответственность, чтобы максимально укрепить себя как мужчину и позаботиться о женщине.",
    "Так же разобраться с мамой и папой, т.к. если он злится на маму (критикует, не принимает ее, обижается не понимает), то в этот период он и не сможет позаботиться о женщине, он не захочет. А если нет хорошего примера, достойного отца, то он будет чувствовать слабость или нежелание быть мужчиной, быть отцом. Если к примеру отец ушел из семьи, то у него может проснуться идея, что быть мужчиной – это плохо и т.д. \n" +
    "Нужно укрепить мужскую энергию и понять, кто он такой как мужчина и не перекладывать ответственность, не искать, кто бы решил его вопросы, а самому решить для себя и почувствовать себя взрослым, сильным мужчиной.",
  ],
  "5": [
    "Этот период в плюсе означает хорошие отношения с законом, важные документы. Часто означает рождение детей, официальный брак, причем часто бывает так, что беременность и свадьба очень тесно связаны и слишком будет всё стремительно закручиваться.",
    "То есть эти события происходят быстро и стремительно и энергия как бы их торопит: быстро выходят замуж женятся, заводят детей.",
    "Хорошие отношения с отцом и с законом, с порядком, порядок в жизни и человек держит все в своих руках, в этот период возможно желание пойти учиться, хорошо себя зарекомендовать, получить уважение по всем аспектам жизни.",
    "А вот в минусе по период приносит проблемы с властью, с отцом, с порядком: что-то пошло не так, всё сбилось, разводы в этот период возможны, проблемы с детьми, беспокойства о детях, неспособность выйти замуж и родить детей, кризис в семейных отношениях. Бывает, что до самого развода дело не дошло, но фактически по карте это развод (фактически в состоянии расхода, но официально не развелись), хотя пятерка связана с законом и для нее очень важен момент официальности и, конечно, нужно выводить эту энергию в плюс: наводить порядок, разложить все по полочкам, изучать законы, как юридические, так и законы мироздания, законы жизни (стараться им следовать). Всячески укреплять семью, заботиться о детях, воспитывать их, но не лезть со своими нравоучениями ни к кому, никого не трогать вообще, немного успокоить свою гордыню из-за знаний. Принять своего отца – это очень важный аспект, с которого нужно начинать работу в этот период для вывода его в плюс – с принятия своего отца."
  ],
  "6": [
    "Плюс периода - это романтика, очень позитивный период. Происходят свадьбы, люди влюбляются, встречаются, т.е. это 100% какая-то любовь. Сердце открывается в это время и человек впускает любимых людей. Так же может означать активную общественную деятельность, много общения, много друзей, приятных событий.",
    "В этот период человек может стать особенно красивым, ухоженным, заняться чем-то красивым, наладить гармонию, но все зависит от того, на сколько Вы человек возвышенный или материальный и земной.",
    "Если материальны, то это работа с внешностью, одежда, внешние отношения, все красиво перед людьми и идеально.",
    "Если человек возвышенный, то красота в глубину идет, Вы может прийти к гармонии внутри себя, познать свои разные стороны и принять себя целостно.",
    "Минус периода, будет затруднять принятие Вами решений, какой-то тяжелый для Вас будет стоять выбор. \n" +
    "Это раны на сердце, это негативные уроки, которые вы не хотели видеть, когда может происходить расставание – разбитое сердце (разрушенное сердце), отношения, любовь. Могут быть измены, развод или не официальный развод, а раскол отношений и сердечный страдания. Отсутствие гармонии, так же могут быть проблемы с сердцем, поэтому в это период нужно присматривать за сердцем, отсутствие радости в этот момент, все не красиво, не гармонично и т.д.",
    "Проработать можно через минус, т.е. через познание врагов обрести друзей. Считается, что человек не может познать любовь, если он не познал нелюбовь и не может обрести друзей и распознать их.",
    "В это период особенно хочется, чтобы все было классно, со всеми дружить при этом можете не замечает недостатков, на все закрывать глаза, лишь бы внешне все было красиво.",
    "Но так или иначе придется пройти познание врагов: людей которые вас подставили, обманули, предали, соврали, не оправдали ожидания, были фальшивыми с вами.",
    "Это нужно для того, чтобы научиться распознавать друзей и врагов.  И как только вы обретает врагов, все в жизни становится на свои места, как ни странно. Вы сразу понимаете, кто друг, кто враг, что делать и как распознавать и т.д. Вот так через негативные события приходите к положительному последствию.",
    "Чтобы научиться видеть все как есть, вам в этот период особенно нужно познать негативные стороны и научиться их принимать и не выдумывать, что все только хорошо."
  ],
  "7": [
    "Плюс этого периода для мужчин, в том, что они могут жениться – это дня них какое-то достижение, победа, лидерство, они берут на себя ответственность.",
    "Если 7ка в плюсе по периоду у женщин в этот период могут выйти замуж, хорошо зарабатывать, достижение целей.",
    "Если 7ка в минусе по периоду и у мужчин, и у женщин – это все наоборот. Не достижение целей, облом, какие-то срывы, прекращение движения, застой, ничего не получается, все останавливается, может даже физически плохо и вы может слечь. Так как тело дает о себе знать.",
    "Склонны к невозможности взять все в свои руки, не возможность вдохновлять других и подавать им пример – одним словом облом. Те цели, к которым вы идете, не получается их достичь.",
    "Поражение, так как энергия периода воинственная и связана с войной, с попытками одержать победу и если в плюсе это победа, то в минусе это поражение, т.е. проигрыш в конкуренции зачастую, если личных отношений касаемо, то может быть кто-то победил в треугольнике, например."
  ],
  "8": [
    "В этот период жизни в плюсе - это значит награда за труды и какая-то справедливость Можно сказать, что радость, окупятся вложения, что-то подтвердится.",
    "В это время, возможно очень часто приобретение недвижимости, оформление важных документов, паспортов, документы для переездов, найти работу, официально выйти замуж/жениться, все что связано с законом и порядком.",
    "Баланс – в это время жизнь благоприятная, все спокойно уравновешено, все получается, и вы как бы имеете почву под ногами и стабильность.",
    "А вот в минусе, то все наоборот: нечестность, не справедливость, вас могут в это время предавать, обмануть, изменить, нарушить закон против вас, нарушить ваши права.",
    "Могут быть проблемы с какими-либо документами, с деньгами, с балансом, больше расходов чем доходов, уходит почва из-под ног, полня нестабильность и чем больше минус, тем сильнее расшатывается ситуация, не чувствуется полностью вообще надежность и основательность. Можно сказать, что в этот период, если вы в минусе, то жизнь кладет «на лопатки». Склонны в это время очень сильно быть возмущены, таким образом раскручивать вокруг себя воронку несправедливости, пытаясь урегулировать ситуацию, которую вы воспринимает, как не справедливую, вы накручиваете ее еще больше, и уже сами начинает нарушать это равновесие и, теперь вас самого нужно успокаивать.",
    "Если бы против вас нарушили какие-то права, то пришлось бы успокоить только того человека, который нарушил, но так как если вы сами включаетесь и начинаете бороться, доказывать и отстаивать, то уже и вас нужно успокаивать. Тогда жизнь дает вас удары, и выбивает почву из-под ног. Тогда вы просто падаете и ничего не можете сделать, ни чего доказать. Любая борьба оборачивается против вас самих.",
    "Когда вы обессилив рухнули на пол, только тогда вы успокаиваетесь и к вам приходит смирение, то есть ваша гордыня пострадала очень сильно и именно в этот момент эта гордыня уходит. Приходит смирение и принятие высших божественных законов, вот тогда в этот момент весы кармы приходят к равновесию, и вы получает награду за свое смирение.",
    "Ситуации могут быть аховые, так как вас может кидать в этот период (подставлять на деньги, на документы, разводы, измены, причем ужасные, страшные, не красивые, друзья кидают обманывают, подлости), но это самая выгодная точка, чем быстрее вы придёте к смирению, тем лучше для вас и тем быстрее исцеляется ситуация в целом."
  ],
  "9": [
    "В этот период в плюсе склонны идти на  обучение, найти друзей, духовных наставников, учителей, руководителей, каких-то проводников к мудрости, священников.",
    "Для женщин может означать беременность, т.к. в это период женщина немного закрывается, и уходит  в себя.",
    "Можете ознаменовать психологию, т.е. можно найти психолога, чтобы помочь разобраться в своих желаниях, в это время можете начать много читать, много думать. Так же можете закрыться от людей, но найти своих единомышленников.",
    "Другой уровень, когда вы может начать обучать людей, т.е. заниматься тем, чем должны  заниматься мудрецы.",
    "А вот минус периода, это всегда болезни, нужно обратить внимание на здоровье.",
    "Это так же уход и закрытость. В этот период можете бросить работу, переехать, уехать, закрыться от всех, перестать общаться, т.е. максимально, все люди, которые несут вред, которые вас путают, в этот период должны отойти.",
    "Можете расходиться с возлюбленными, от всех уйти и пытаться разобраться в себе, это может быть очень стрессово для вас, так как можете ничего не понимать и обязательно нужен мудрец в это время (психолог, наставник и т.д.). Иначе тогда вы можете совсем уйти от мира, уйти в себя и так и не в чем не разобраться.",
    "Задача -  остаться верными себе, не поддаваться на влияние людей, делать то что вам нужно, а не то что от вас хотят."
  ],
  "10": [
    "Плюс Вашего периода, означает, что включается удача и помощь свыше. Именно сейчас вам могут предложить помощь, что-то для вас сделать, организовать. Фактически все за вас устроить, и вы как бы приходите на все готовенькое или то, что никак не получалось, вдруг кто-то помог и все получилось.",
    "Так же это всегда означает радостный период, радость, удачу,  счастливое течение обстоятельств, в нужное время с нужными людьми, так же означает, что в это время могут появляться позитивные и влиятельные люди, увлечения интересные новые, то что закручивает новый поток, м.б. путешествия, командные слаженные работы, обязательно что-то связанное с поездками, т.к. колесо – колесит по жизни, поэтому в это период м.б. много поездок: все об колесить, посмотреть, путешествия, впечатления, вместе с кем-то, все интересно и увлекательно, развлечения, какие-то.",
    "А вот минус периода — это наоборот: несчастливое стечение обстоятельств, плохое влияние людей, буквально кто-то может перехватывать вашу удачу, провоцировать ваше несчастье. Несчастные случаи, м.б. не тот момент, не тот поток, не те люди вокруг, закрываются какие-то возможности, то есть в целом переживать невезение: все было хорошо, все хорошо шло, но обломалось.",
    "Склонны. в это время к Пассивности. Потеря цели: вы никуда не движетесь, стоите на месте, а может даже и лежите. Бывает так, что появляется влиятельный человек, который оказывает очень плохое влияние, берет вас в оборот и приводит к не везению, а вы цепляетесь за авторитет и за сильную личность."
  ],
  "11": [
    "Плюс периода в усилении, словно появилась двойная сила. У вас появляется двойная активность, это м.б. две работы, как двойной объем, и там, и там, везде успеваете, становитесь очень активным, очень деятельным. Возможны творческие успехи, спорт появляется в жизни, состояние любви к жизни, наполненности, созидательная сила, когда Вы чувствует себя очень крепким, так же м.б. свой бизнес, то что вы любите, как хобби и как работа. Это как трудоголизм в хорошем его значении: можете заняться видом деятельности, который любите. У женщин может наступить беременность – как два человека в одном.",
    "Минус периода как обратная картина: истощение, все делаете через силу, те же двойные объемы работы, но вы не справляетесь, так как нет жизненных сил и даже для того, чтобы что-то начать.",
    "Это не хороший период чтобы рваться вперед, лучше в тот период себя максимально укреплять, давать себе расслабление, как тренировки: напрягся-расслабился, тогда появится жизненный тонус.",
    "Обязательно нужно заниматься тем, что любите, т.к. в минусе периода склонны делать, то что не любите, фактически насиловать себя и если вы дойдете до совсем сильного насилия над собой, то на вас могут морально давить, прогибать, наказывать, насиловать, даже физически. Кто-то обязательно во внешнем мире реализует вам ваше внутренне состояние, если вы давите на себя, то на вас начнут давить со стороны, если вы насилуете себя, то насилие над вами будет и внешнее, кто-то будет насиловать вас.",
    "Поэтому вам особенно важно сейчас это понять, что бы Вы остановились, это будет особенно сложно, но нужно и важно.",
    "Вас так же могут одолевать в это время страсти: Хочу денег, хочу секса, хочу влияния, хочу успехов, хочу, хочу, хочу…. И настолько все это рвет, что вы, как паровоз начинаете переть, но по очень опасной дороге и заходить так далеко, истощить себя, создать себе такие проблемы, такие нервные перегрузки, какие вы просто можете не потянуть. Тут нужно вспомнить, что есть Бог, что есть его любовь и нужно немного бережно к себе относиться и страсти свои усмирить."
  ],
  "12": [
    "Ваш период сейчас в плюсе означает позитивные изменения, что-то однозначно новое. Это м.б. творчество, а м.б. и беременность, т.к. это перемены, творчество, создание новой жизни, двойная любовь, когда вам всецело открывается любовь для себя. Так же, помощь людям, проекты новые путешествия, и т.д. м.б. соц. мероприятия, обучение новое, что-то новое и необычное.",
    "Минус периода приносит - страдания и жертвенность. Это могут быть всякие аварии, поломки, несчастные происшествия, где вы в итоге оказываетесь пострадавшими ( м.б. ДТП, операции, болезни, вдруг кто-то обидел ни за что на ровном месте выгнали с работы, оскорбили, лишили премии, не заплатили за работу).",
    "Сейчас вы особенно подвержены проработке бескорыстности и жертвенности и поэтому склонны часто попадать в ловушку бесплатной работы: делать работу, а за это ничего не получить. Так же в это время может кто-то очень сильно вас мучить, тогда нужно отпустить страдания и перестать быть жертвой. Еще может быть зависание, замирание. Это означает, что вы застряли в прошлом, что-то вас не пускает, хотя вы рванули до какого-то этапа, но что-то не пустило вперед, может старые эмоции.",
  ],
  "13": [
    "В плюсе период приносит кардинальные перемены, перерождение, трансформацию жизни: Смена работы, смена деятельности, обстоятельства жизни резко изменились. Вы это легко принимаете, переход на другую работу, на прежнем месте все зачахло, а вы открыли себя в новой роли в новом месте. Могут рождаться дети в это время, очень часто с ребенком и мы перерождаемся, и мы даем новую жизнь.",
    "Может меняться стиль жизни в целом: стиль одежды и образа, мысли, дела, интонации, новые знания, поездки, новый бизнес, как новые риски, экстремальный вид спорта в это время может появиться в увлечениях.  Главное, что это бесповоротные изменения и нужно им открыться. Да могут быть острые ощущения, но позитивные.",
    "В минусе энергия периода приводит к тяжелым ситуациям, возможна в буквальном смысле смерть: смерть кого-то из близких, смерть, которая очень сильно повлияет на вас. Как конец прошлой жизни, те изменения, с которыми уходит целый кусок жизни, прожитый до этого. Неприятные моменты, внутренний кризис, как проявление психической смерти, т.е. вы внутри себя переживаете смерть всех своих мыслей, чувств и зарождаются новые чувства, но они могут доставлять большую боль в этот момент, т.к. вы не можете применить их к своей старой жизни.",
    "Могут быть ситуации, когда нужно всё поменять, но вы не можете ведь у вас нет на это сил.",
    "Если в плюсе вы это делаете легко, то в минусе, у вас нет сил чтобы принять перемены. Вы держитесь за старое изо вех сил, пытаясь дать вторую жизнь тому что у вас есть, но это чаще всего не получается и в итоге спустя время вы теряете то за что так цеплялись, но с большими потерями и с тяжелейшими душевными ранами.",
    "В это период можете быть нерешительными, неответственными, необязательными: не взяться за новое или начать, сделать прорыв, а потом резко затормозить.",
    "Склонны проявлять жестокость по отношению к другим людям.",
    "Вообще это резкий период всегда и несет сильные изменения и если вы откроетесь для нового, то эти изменения приведут вашу жизнь к лучшему. Но если будете цепляться за старое, то естественно лучшего не получите, а старое до разрушится и вам придётся остаться на руинах, плакать и сожалеть, что потеряли еще и время.",
    "В целом период в минусе это глубокое и болезненное изменение внутри вас."
  ],
  "14": [
    "В плюсе период принесёт радость души. Словно душа радуется, смеется, веселится. Даже может родится ребенок, может случится неожиданная радость, может найтись хороший душевный друг. Может появиться идея увлечься психологией и самопознанием. Хочется проводить много времени на природе, в путешествиях и изучении языков. Но что бы с вами не произошло, все означает – радость души и способность прийти к золотой середине, словно выровнять жизнь, когда повсюду хорошие позитивные эмоции, когда все становится классно!",
    "Минус периода в противоположности: это душевные муки и терзания, склонны глушить себя каким-то образом, доводить себя до безумных стрессов и потрясений (пить, курить, гулять, притуплять ощущения), что бы не было больно пуститься во все тяжкие и не редко впадать в крайности.",
    "Эмоционально происходит переворот. Эмоции настолько зашкаливают, что вы не справляетесь. Поэтому вам нужно обязательно прорабатывать эмоции, обиды, прощения, заниматься исцелением души.",
    "Так же может означать разлуку с душевными друзьями, кто-то из близких ушел в темное состояние и вам приходятся жить рядом с пострадавшим , т.е. страдания приходят не через вас, а через близких: когда невозможно на все смотреть, но нельзя и избежать.",
    "М.б. депрессии, подавленность, безвыходность, тупик, апатия и плачет душа. Апатия уже последний этап: вначале вас в разные стороны мотыляет, вы разрываетесь, душа разрывается, когда вы в прямом смысле считает, что смерть – это самое лучшее, что может случиться. Понятно что происходят обстоятельства приводящие к этому состоянию: предательства, потери, что может конкретно произойти самоубийство, это душевные муки и терзания."
  ],
  "15": [
    "Это период в плюсе приносит денежные прибыли, улучшение материального положения: новая работа, какой-то достаток. Яркий период, где вы выходите на высокий уровень и ощущаете позитивную власть влияния на людей.",
    "Так же можете в этот период начать проявлять щедрость, связываться с Богом: быть более отдающим, любящим, открытым, чистым. Очищаться в этот период (уходить в религию, в посты), воздерживать себя, отказываться от земных искушений, садиться на диету. Все контролировать, исцеляться от пороков и зависимостей, больных желаний, неумеренности в чем-то.",
    " Минус периода приносит противоположность плюсу: вы склонны поддаться всем порокам: гулять, при чем даже не задумывается об этом, не можете себе отказать в удовольствиях. Можете начать разводить людей на деньги, думая, что это все сойдет вам с рук. Вступать в разные аферы, вообще подвержены терять очень большие деньги, то есть Это темные всякие дела, авантюры, бандитизмы, плохие люди вокруг, искушения, могут вылезти разные пороки и зависимости: алкоголизм и наркомания. В целом это Мрак и темнота. Вы обнажает все свое тайные и плохие стороны и все тайное становится явным. Чаще всего это денежные потери так как это проволочки дьявола, сам дьявол играет с вами в это время."
  ],
  "16": [
    "Плюс этого периода даёт новые знания, духовное пробуждения. В это время может появиться учитель, духовный наставник, человек, который откроет глаза на важные вещи. Это разрушение старых взглядов на жизнь и закладывание фундамента для новых взглядов. Представьте башню, только мысленную, ментальную.",
    "Перестройка вас самого в это время, то есть может войти в какую-то систему или структуру, в религию, может начать молиться в это время, что всегда хорошо.",
    "Ремонты в это период могут быть: Что-то чинить, ремонтировать, перестраивать.",
    "Меняется обстановка вокруг, поэтому происходят и внутренние изменяя.",
    "Внешнее изменяя, как результат трансформаций. И т.к. в плюсе вы чувствуете себя очень сильным духом, т.к. идет озарение, вы все понимаете, осознаете и буквально прозреваете. Тут же приходит понимание как жили, как сейчас живете, к чему стремиться и обретаете смысл жизни.",
    "Например: были честными всегда и во всем, но пришла ситуация, где вы увидели, что честность вредит и что честным быть не всегда хорошо. Тогда меняется система ценностей, внутренний стрежень меняется, и должен укрепиться, и вы обретаете свою силу, связь с богом, поэтому вы и становитесь созидательным, т.к. Бог – это любовь. Любовь – это созидание, движение вперед, создание жизни вокруг себя",
    "В минусе период приносит разрушение: духовные падения как гром среди ясного неба, когда все начинает рушиться, ломаться, биться, разрушаться отношения, тело, работа, деньги, все-все. Вы должны это пережить. В зависимости от тог, как вы далеко зашли, от этого будет и масштаб разрушений и потерь.",
    "Вы будете проходить очень большие испытания, там, где проверяется ваша стойкость и сила духа. Если вы не сильно далеко зашли, вы гибкий и податливый, то испытания не будут такими уж зверскими.",
    "Очень подвержены всяческим авариям, переломам, крахам, болезням, обострениям, инсультам, то что ВДРУГ вас разбило, параличи и т.д., то что вас разрушает и всю вашу жизнь в этот момент. Тогда нужно идти к богу, и другого пути не существует, потихоньку восстанавливаться, находить людей с позитивным сознанием, светлых и духовных людей, которые помогут восстановить здоровье, взгляды на жизнь.",
    "Так же в этот период склонны разрушить своё эго. Ломаются ценные вещи, разрушаются отношения. В целом это крах, ради обретения истинных целей и новых смыслов жизни. А почему происходит крах? Потому что вы могли выстроить свою башню на ложных убеждениях и взглядах. Все, что выстроено ложно, должно быть разрушено в этот момент. Обычно, если отношения встроенные на иллюзиях, то они разрушаться, если работа – то работа, к телу относились плохо, то с телом будут проблемы."
  ],
  "17": [
    "В плюсе периода бурлит творчество: это слава, это карьерный рост, повышение, вообще социальный рост.",
    "Вы можете продвинуться в это время до каких-то высот, кто-то может лоббировать ваши интересы. Вы словно заблистали, засветились, засверкали, заявили о себе. В это время вы можете выйти на сцену, стать заметным, ярким, привлекательным для людей и тогда о Вас узнают все. Так же может быть, что вас остро увлечет какое-то творчество, пойдете на какие-либо кружки или курсы.",
    "Можете выйти к людям, что-то делать для людей вышивать, шить, придумывать, разные дизайны, рекламу делать, любое творчество, т.е это будет яркий период жизни. Вы именно сейчас очень привлекательные и внешне, и внутренне, все на вас западают.",
    "Стали буквально яркими, блестящими, очень красивыми.",
    "А вот минус периода - это наоборот мрак! Это потухшая звезда. Вы не можете ни как реализовать себя для людей, не можете раскрыть свои таланты, не видите и не понимает их, и тогда происходит крах.",
    "Может также означать, что вы можете заболеть, как быть потухшим, подавленным, можете испытать позор, что-то случается за что вам очень стыдно и страшно рассказать, что кто-то узнает. Можете в этот период прятаться, а вот причина того, что прячетесь, то что вы не видите в чем можете принести пользу людям."
  ],
  "18": [
    "Данный период принесёт вам смену окружения – это хорошее окружение, т.е. вы поменяете окружение на более лучшее: это веселые, светлые добрые люди, креативные- это самое распространенное, но также м.б. творчество, м.б. переключение на позитивное мышление, самовыражение через творчество: актерский кружок, занятия искусством, увлечение литературой, кино, модой, красотой, т.е. включился поток воображения.",
    "Больше всего это влияет на то, как вы перестраиваетесь. Но в целом сплочение и притяжение хорошего в свою жизнь",
    "Минус периода – это темное окружение, плохое влияние, сплетни, завистники, негативщики вокруг, пьяницы, гулящие, наркоманы, депрессивные люди и вы сами в этот период может уйти депрессию. Чаще всего настоящее окружение оказывает на вас очень плохое воздействие и вы ни как не может избавится от этого окружения, именно по этой причине  можете уходить в серьезную депрессию.",
    "Так же в эти периоды на вас могут действовать порчи, сглазы, если вы негативно мыслите и принимаете эти вибрации, а если мыслите позитивно, то на вас ни какие проклятия и порчи не подействуют. А действовать может в том случае, когда есть страх и негативное состояние, тогда эти вибрации будут созвучны с вами, тогда они на вас действуют и ухудшат ваше положение, ведь вы в таком случае будете ослаблены страхами.",
  ],
  "19": [
    "В плюсе этот период может принести новую работу, либо повышение, улучшение материального положения, выход на новый уровень материальных благ, процветание, успех, достижение в социальном плане, уважение людей, а так как этот период дает уважение, то это авторитет и процветание, не только финансовое, но и социальное, подчеркивание статуса, высокие должности, хорошие связи и т.д. Яркие знания. Вы может начать делиться знаниями в это момент, стать известным популярным и т.д. , как бы засветить всем.",
    "В минусе периода это всё наоборот: проблемы в работе, вынужденная смена работы, м.б. денежные потери, денежные трудности, напряжение и раздражение. Зацикленность на какой-то цели, сложные испытания, тяжести, негатив, тьма, уход в минус по деньгам, авторитету и знаниям. Даже может казаться, что именно сейчас многие от вас отворачиваются, вы можете потерять уважение, потерять социальное процветание. А также можете сталкиваться со своими страхами, связанными, с тем, что вас могут не принимать, что нет любви людей, с деньгами всё туго, что может быть бедность. Если нет страхов, то минус пройдет мягче. Страх бедности и нищеты и страх богатства энергии денег с которой можете не справиться, это две стороны одной медали. – это нужно прорабатывать. Может случиться пожар."
  ],
  "20": [
    "В плюсе период даёт позитивные изменяя в роду: рождение детей, помощь рода, поддержка. В это время хорошие возможности приходят чтобы расширить свои перспективы, деятельность, переехать в другую страну или город, на большие горизонты, выйти на новый уровень, стать популярным, известным, продвинуть себя в интернете, или телевидении, начать вещать и передавать информацию. Открывается хорошая вязь с богом, т.е.  приносит духовное развитие.",
    "Минус периода проходит наоборот, как очень тяжелый период, когда в роду м.б. тяжелые испытания, может кто-то умрет в роду, м.б. осуждение в роду или какие-то неприятности, плохие встречи, отношения, ссоры с родственниками, отречения от рода, м.б. кто-то смертельно болен или отсутствие поддержки в тяжелой ситуации, словно весь род отворачивается от вас, все рассорились. Вы можете оказаться на грани между жизнью и смертью, т.е. очень опасный период. Негатив, критика, осуждение, агрессия, непринятие мира и жизни в целом, вообще протест против жизни идет в этот период и это может обернуться фактически умиранием, может в переносном смысле, а может буквально смерть или болезнь.",
    'Сужение возможностей: например, очень хочется в это период переехать, выйти на новый уровень, заявить о себе, что-то вещать, а не получается, есть в этом какие-то препятствия. Так же это время, когда выходит плохая карма рода и ее нужно проработать и исцелять. Это дает нагрузку на вас и нервное напряжение. Так же М.б проблемы с судом.'
  ],
  "21": [
    "Плюс этого периода открывает всевозможные переезды, как расширение: где новые люди, новые связи, выход на новый уровень, путешествия. Но чаще всего это переезд, а потом все остальное. Если не было переезда, возможно переход на новую работу, новое место, к новым людям, новые знакомства и все в жизни поменялось и поездки путешествия, т.е. расширение какое-то и процветание. Хорошие возможности, расширение возможностей.",
    "А вот минус периода - это долги, проблемные переезды, когда вы вынуждены что-то менять. Обычно очень тяжело в этот момент, всего не хватает, тяжело переехать, через большое преодоление. Нужно разбираться с благодарностью, довольством –недовольством. Открыться энергии бога и изобилия, не закрываться от мира, не ограничивать себя, открываться для новых знаний, учить языки, это все что нужно что бы вывести период в +, а также принимать мир целым, перестать воевать, быть мирным и добрым внутри себя, быть добрым по отношению к себе. И это важно, т.к. наш мир начинается с нас самих. Наша любовь к себе показывает насколько мы способны любить всех остальных. Так же м.б. сужение возможностей"
  ],
  "22": [
    "Плюс периода - это что-то с нуля, как новая жизнь. У вас начнется все с чистого листа: новая жизнь, новая работа, новые отношения, новые дело, увлечение, и новая жизнь буквально - рождение ребенка.",
    "Суть периода: начать что-то новое, открыться для него, позволить себе отпустить все, что было и что уходит, открыться и принять это, и тогда все произойдет в плюсе.",
    "Это так же м.б. веселый период, потоковый, много поездок, путешествий приключений, новые знакомства, расширение какое-то деятельности, активности, своей географии, самопознание, ощущение что вы вырвались на свободу, вы отрываетесь, все границы сняты и ограничения. То, что вас держало «В КЛЕТКЕ», замыкало, вы не могли двигаться свободно по жизни - эти границы открываются и снимаются ограничения",
    "Минус периода проявляется в ограничениях свободы, это м.б. разные проявления: психические срывы, тупик, не возможно найти выход, вырваться на свободу. Это м.б. нарушенные связи с миром, негативные путешествия, плохие приключение: «нашел приключения на свою голову», можете попадать в тюрьму, ввязаться в нехорошие дела, где мыслили безответственно и вас призвали к ответственности, вогнали в социальные рамки и призвали к ответу.",
    "Тут главное в этот период начать все с нуля и открыться для нового, вырваться и позволить себе выйти из привычных укладов.",
    "М.б. болезни, те которые ограничивают физически.",
    "М.б. безвыходные ситуации в отношениях: родителями, с партнерами, с друзьями, с любимыми, не важно но главное это ограничение свободы.",
    "Так же это может означать и почти всегда означает, что вы словно выходите в ноль, то есть обнуляется ваша жизнь по всем фронтам, все что было ушло и тогда вы вынуждено открываетесь для нового.",
    "Разница + и – в том, что в + периода вы сами хотите нового, вам это весело и интересно, вы с увлечением открываетесь для нового, а вот в минусе вы вынуждены идти во что-то новое и для вас это очень тяжело, а может и опасно для вашей же свободы.",
    "В этот период особенно важно следить за нервной системой.\n" +
    "Основная задача не уйти в мир иллюзий, не спиться, не снаркоманиться, не повестись на денежные авантюры, не связаться с преступными и асоциальными элементами, чтобы не попасть в  иллюзию «свободы и несвободы»."
  ]
}

export default decryptPeriods