import React from "react";

const Health = () => {
  return (
    <svg
      width="550"
      height="550"
      viewBox="0 0 550 550"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M207.912 218.297C207.102 217.234 208.477 216.217 209.81 216.311C227.039 217.52 243.197 220.573 256.849 232.65C254.451 230.251 252.396 227.523 249.755 225.395C245.11 221.655 239.872 218.843 234.299 216.7C226.599 213.95 218.899 212.3 211.199 211.2C204.599 210.65 197.999 210.1 191.399 210.65C178.749 211.75 165.549 211.2 152.899 211.2C145.749 211.2 138.049 210.1 130.899 209.55C125.949 209 120.449 209 115.499 208.45C108.899 207.9 102.849 206.8 96.2492 205.7C91.2992 205.15 86.3492 204.6 81.9492 204.05C76.4891 203.504 71.029 202.416 66.107 201.324C66.0352 201.308 66.0483 201.296 65.9749 201.301C65.5254 201.334 65.749 201.935 66.1827 202.058C105.398 213.14 144.624 219.927 185.359 217.168C194.575 216.544 202.778 222.236 205.699 231C208.999 244.2 212.849 257.4 216.149 270.6C218.349 279.95 220.549 289.3 222.749 298.65C224.399 305.8 225.499 313.5 226.599 321.2C227.149 327.25 227.699 333.3 227.699 339.9C227.699 345.95 227.699 351.45 227.699 357.5C227.699 362.45 226.599 367.4 226.049 372.35C224.949 382.25 222.749 391.6 220.549 401.5C219.783 403.799 223.326 402.813 224.086 400.512C224.415 399.516 224.727 398.538 224.949 397.65C226.599 390.5 228.249 382.8 229.899 375.65C230.999 370.15 231.549 364.1 231.549 358.6C232.099 351.45 232.649 344.85 232.099 337.7C232.099 331.1 230.999 325.05 230.449 318.45C228.799 302.5 224.949 286.55 221.099 271.15C218.349 259.05 215.599 247.5 212.849 235.95C211.785 230.631 210.207 225.311 208.612 219.495C208.505 219.105 208.157 218.619 207.912 218.297Z"
        fill="url(#paint0_linear_185_1520)"
      />
      <path
        d="M356.929 490.244C356.163 494.196 359.683 496.167 359.698 492.141C359.7 491.839 359.722 491.55 359.767 491.25C360.822 484.25 361.361 477.25 361.899 470.25C362.449 459.25 362.999 448.25 362.999 437.25C362.999 430.65 362.449 424.6 361.899 418C360.249 402.05 358.049 386.1 354.199 370.7C352.549 363.55 350.899 356.4 349.799 348.7C349.249 344.85 348.699 340.45 348.699 336.6C349.249 325.6 350.349 315.15 356.399 305.8C358.599 302.5 361.349 299.2 363.549 295.9C364.91 294.085 362.565 291.231 360.46 292.075C360.117 292.213 359.776 292.349 359.436 292.485C359.246 292.561 359.188 292.57 358.991 292.624C358.639 292.721 358.474 293.305 358.248 293.591C351.805 301.714 346.47 310.396 344.849 321.2C343.749 328.9 343.199 336.6 343.749 343.75C344.299 350.35 345.399 356.95 347.049 363C349.249 374 351.999 384.45 353.649 395.45C355.299 403.7 356.399 411.4 356.949 419.65C358.049 427.35 358.599 435.05 358.599 442.75C359.149 451 359.149 458.7 359.149 466.95C359.149 473.857 358.123 480.25 357.557 487.122C357.517 487.606 357.429 488.084 357.308 488.554C357.161 489.129 357.036 489.691 356.929 490.244Z"
        fill="url(#paint1_linear_185_1520)"
      />
      <path
        d="M243.426 148.361C245.272 148.554 246.019 146.664 244.2 146.3C237.05 144.65 229.35 143.55 221.65 144.1C213.4 144.65 205.15 145.75 197.45 146.85C188.65 148.5 180.4 151.25 171.6 152.9C169.043 153.539 166.487 153.807 164.039 154.027C160.646 154.332 157.24 154.55 153.833 154.55C151.688 154.55 149.544 154.55 147.4 154.55C139.7 154 132.55 153.45 124.85 152.9C117.7 152.35 111.1 151.25 103.95 150.15C98.4496 149.6 92.9496 147.95 87.4496 147.4C81.9895 146.308 75.9873 145.758 70.5193 144.674C70.4395 144.658 70.3606 144.651 70.2793 144.648C69.1468 144.609 69.1199 145.216 70.2058 145.54C75.2204 147.037 80.235 148.782 85.2496 150.15C94.5996 152.35 104.5 155.1 114.4 157.3C119.35 158.4 124.3 158.95 129.25 159.5C138.6 161.15 148.5 161.7 157.85 161.15C166.1 160.6 174.35 159.5 182.6 157.3C191.4 155.1 200.2 152.9 209.55 150.7C215.6 149.6 221.65 149.05 227.7 148.5C232.982 147.972 238.265 147.824 243.426 148.361Z"
        fill="url(#paint2_linear_185_1520)"
      />
      <path
        d="M320.1 146.3C318.45 139.7 316.8 133.1 315.15 126.5C311.958 116.391 308.765 106.281 305.573 96.1719C305.359 95.4946 305.25 94.7921 305.25 94.0818C305.25 93.3345 305.371 92.589 305.605 91.8794C307.685 85.5817 309.739 78.8446 311.3 72.6C312.4 68.75 312.4 64.9 312.95 61.05C313.5 58.3 313.5 55.55 313.5 52.8C313.5 43.45 311.85 34.65 308 26.4C304.15 18.15 298.1 12.1 289.85 7.69998C282.15 3.29998 273.9 1.09998 265.1 1.09998C264.554 1.09998 265.364 1.18742 265.882 1.36197C270.678 2.97896 276.386 5.06447 281.6 7.14997C294.25 13.2 302.5 23.1 305.25 37.4C306.9 45.1 306.9 52.8 306.35 60.5C305.285 71.6839 302.673 82.3522 298.513 93.0041C298.243 93.6961 298.048 94.3766 298.162 95.1105C298.284 95.8919 298.802 96.6281 299.094 97.363C302.246 105.288 305.398 113.67 308.55 121.55C311.3 127.6 314.05 133.65 316.8 140.25C317.35 141.9 318.45 144.1 320.1 146.3C319.55 146.3 320.1 146.3 320.1 146.3Z"
        fill="url(#paint3_linear_185_1520)"
      />
      <path
        d="M359.283 291.274C358.495 292.381 359.248 292.342 360.504 291.825C362.124 291.157 363.914 290.406 365.564 289.723C367.137 289.073 368.522 288.326 369.34 286.834C370.339 285.013 371.184 282.834 372.11 280.974C376.571 272.012 380.861 263.006 383.349 253.55C384.999 246.4 386.649 239.25 388.299 232.1C389.949 224.95 389.399 217.25 389.399 209.55C389.399 202.4 391.599 195.8 396.549 190.3C400.399 186.45 405.349 184.25 410.299 183.15C416.899 181.5 422.949 179.85 428.999 177.65C441.649 173.25 454.299 168.85 466.949 163.9C474.596 161.169 481.7 157.353 489.339 153.53C489.444 153.477 489.571 153.459 489.683 153.424C490.22 153.256 489.455 152.642 488.938 152.864C487.108 153.651 485.279 154.185 483.449 155.1C469.699 161.15 454.849 166.1 440.549 170.5C431.199 173.25 421.299 176 411.949 178.75C406.999 179.85 402.049 181.5 397.649 184.25C390.499 189.2 386.099 196.35 385.549 204.6C384.999 209.55 384.999 215.05 384.449 220C383.899 228.25 382.249 236.5 380.599 244.2C377.299 255.75 372.899 267.3 367.399 277.75C366.187 280.174 364.678 282.894 363.091 285.476C361.986 287.272 360.507 289.557 359.283 291.274Z"
        fill="url(#paint4_linear_185_1520)"
      />
      <path
        d="M314.107 309.907C313.866 311.961 316.299 313.234 317.847 311.863C318.327 311.439 318.44 310.939 318.506 310.302C318.628 309.139 318.931 307.838 319.272 306.685C319.447 306.093 319.598 305.493 319.7 304.884C321.946 291.487 326.26 279.119 331.1 266.75C334.4 257.95 337.15 249.15 338.8 239.8C340.45 229.9 341 220 340.45 210.1C339.9 202.4 338.25 194.7 336.05 187C333.315 178.794 330.035 171.132 325.129 164.015C325.077 163.938 325.017 163.867 324.952 163.802L324.943 163.793C324.659 163.51 324.304 163.238 324.056 163.554C323.956 163.682 323.975 163.979 324.031 164.132C324.602 165.705 325.626 167.277 326.15 168.85C330 177.65 332.2 186.45 333.3 195.8C334.95 205.15 334.95 213.95 334.4 223.3C333.85 231 332.75 239.25 330.55 246.95C328.35 255.75 325.05 264.55 322.3 273.35C319.55 282.15 317.9 291.5 315.7 300.85C314.988 302.985 314.507 306.5 314.107 309.907Z"
        fill="url(#paint5_linear_185_1520)"
      />
      <path
        d="M344.563 141.728C343.527 143.074 345.081 144.146 346.402 143.078C346.467 143.026 346.527 142.969 346.583 142.907C350.976 138.015 356.988 135.286 363 133.1C370.15 130.35 377.3 128.7 384.45 128.15C388.85 127.6 393.8 127.6 398.2 127.05C400.794 127.05 403.398 127.911 405.958 127.49C409.147 126.966 411.923 125.49 414.7 124.3C429.55 118.8 444.4 113.3 459.25 108.35C465.228 106.24 471.207 104.355 477.329 102.407C478.487 102.038 478.048 99.9003 476.85 100.1C468.6 101.75 460.35 103.4 452.1 105.6C440.55 108.9 428.45 112.75 416.9 116.6C413.05 117.7 409.2 119.35 405.35 121C404.25 121.55 403.7 121.55 402.6 121.55C397.1 121.55 391.6 121.55 386.1 122.1C374 123.2 362.45 126.5 352 134.2C349.215 136.428 346.713 138.937 344.563 141.728Z"
        fill="url(#paint6_linear_185_1520)"
      />
      <path
        d="M263.533 530.666C263.64 530.559 264.034 530.408 264.057 530.558C264.402 532.783 264.515 535.016 264.733 537.427C264.9 539.281 267.2 541.017 267.747 539.238C267.928 538.65 267.886 537.953 267.861 537.296C267.848 536.966 267.878 536.637 267.926 536.31C268.336 533.556 268.411 530.449 268.761 527.293C268.871 526.297 268.935 525.274 269.34 524.357C269.609 523.747 269.973 523.112 270.281 522.549C270.498 522.154 270.769 521.755 271.068 521.417C274.446 517.595 272.029 513.983 268.088 517.222C260.568 523.402 253.026 532.873 248.519 543.627C247.883 545.145 249.636 546.763 250.799 545.6C255.107 540.754 258.888 535.907 263.174 531.061C263.28 530.94 263.419 530.78 263.533 530.666Z"
        fill="url(#paint7_linear_185_1520)"
      />
      <path
        d="M144.648 538.45C176.349 501.283 203.162 460.858 219.149 413.935C219.333 413.394 219.193 412.795 218.789 412.39C218.728 412.33 218.662 412.264 218.58 412.182C218.432 412.033 218.258 411.986 218.073 411.887C217.553 411.608 217.033 411.52 216.849 412.081C201.395 459.408 175.184 500.281 144.648 538.45Z"
        fill="url(#paint8_linear_185_1520)"
      />
      <path
        d="M320.649 381.172C320.649 381.489 320.767 381.451 320.699 381.142C316.196 360.733 314.972 339.928 317.381 319.079C317.801 315.443 313.499 312.59 313.499 316.25C313.499 323.4 312.949 330 312.949 337.15C313.499 344.85 314.049 352.55 315.699 360.25C316.798 367.39 318.993 373.982 320.642 381.12C320.647 381.14 320.649 381.151 320.649 381.172Z"
        fill="url(#paint9_linear_185_1520)"
      />
      <path
        d="M355.121 515.189C355.627 513.128 353.306 511.815 353.113 513.928C353.095 514.131 353.079 514.351 353.038 514.552C350.838 525.451 347.559 536.345 343.199 546.7C342.951 547.197 343.225 547.373 343.481 546.88C347.238 539.632 353.085 523.482 355.121 515.189Z"
        fill="url(#paint10_linear_185_1520)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_185_1520"
          x1="65.299"
          y1="303.067"
          x2="256.789"
          y2="303.067"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F613B" />
          <stop offset="0.0430535" stopColor="#876A42" />
          <stop offset="0.1144" stopColor="#9D8455" />
          <stop offset="0.1224" stopColor="#A08757" />
          <stop offset="0.1603" stopColor="#AB9461" />
          <stop offset="0.2221" stopColor="#C5B278" />
          <stop offset="0.2857" stopColor="#D7C687" />
          <stop offset="0.3395" stopColor="#EADB97" />
          <stop offset="0.4454" stopColor="#E5D593" />
          <stop offset="0.5625" stopColor="#CFBC7F" />
          <stop offset="0.7336" stopColor="#B59D66" />
          <stop offset="0.8686" stopColor="#927546" />
          <stop offset="1" stopColor="#826337" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_185_1520"
          x1="343.514"
          y1="393.543"
          x2="368.491"
          y2="393.543"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F613B" />
          <stop offset="0.0430535" stopColor="#876A42" />
          <stop offset="0.1144" stopColor="#9D8455" />
          <stop offset="0.1224" stopColor="#A08757" />
          <stop offset="0.1603" stopColor="#AB9461" />
          <stop offset="0.2221" stopColor="#C5B278" />
          <stop offset="0.2857" stopColor="#D7C687" />
          <stop offset="0.3395" stopColor="#EADB97" />
          <stop offset="0.4454" stopColor="#E5D593" />
          <stop offset="0.5625" stopColor="#CFBC7F" />
          <stop offset="0.7336" stopColor="#B59D66" />
          <stop offset="0.8686" stopColor="#927546" />
          <stop offset="1" stopColor="#826337" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_185_1520"
          x1="66.9485"
          y1="152.658"
          x2="259.539"
          y2="152.658"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F613B" />
          <stop offset="0.0430535" stopColor="#876A42" />
          <stop offset="0.1144" stopColor="#9D8455" />
          <stop offset="0.1224" stopColor="#A08757" />
          <stop offset="0.1603" stopColor="#AB9461" />
          <stop offset="0.2221" stopColor="#C5B278" />
          <stop offset="0.2857" stopColor="#D7C687" />
          <stop offset="0.3395" stopColor="#EADB97" />
          <stop offset="0.4454" stopColor="#E5D593" />
          <stop offset="0.5625" stopColor="#CFBC7F" />
          <stop offset="0.7336" stopColor="#B59D66" />
          <stop offset="0.8686" stopColor="#927546" />
          <stop offset="1" stopColor="#826337" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_185_1520"
          x1="264.644"
          y1="73.7123"
          x2="320.083"
          y2="73.7123"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F613B" />
          <stop offset="0.0430535" stopColor="#876A42" />
          <stop offset="0.1144" stopColor="#9D8455" />
          <stop offset="0.1224" stopColor="#A08757" />
          <stop offset="0.1603" stopColor="#AB9461" />
          <stop offset="0.2221" stopColor="#C5B278" />
          <stop offset="0.2857" stopColor="#D7C687" />
          <stop offset="0.3395" stopColor="#EADB97" />
          <stop offset="0.4454" stopColor="#E5D593" />
          <stop offset="0.5625" stopColor="#CFBC7F" />
          <stop offset="0.7336" stopColor="#B59D66" />
          <stop offset="0.8686" stopColor="#927546" />
          <stop offset="1" stopColor="#826337" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_185_1520"
          x1="358.434"
          y1="222.487"
          x2="490.007"
          y2="222.487"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F613B" />
          <stop offset="0.0430535" stopColor="#876A42" />
          <stop offset="0.1144" stopColor="#9D8455" />
          <stop offset="0.1224" stopColor="#A08757" />
          <stop offset="0.1603" stopColor="#AB9461" />
          <stop offset="0.2221" stopColor="#C5B278" />
          <stop offset="0.2857" stopColor="#D7C687" />
          <stop offset="0.3395" stopColor="#EADB97" />
          <stop offset="0.4454" stopColor="#E5D593" />
          <stop offset="0.5625" stopColor="#CFBC7F" />
          <stop offset="0.7336" stopColor="#B59D66" />
          <stop offset="0.8686" stopColor="#927546" />
          <stop offset="1" stopColor="#826337" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_185_1520"
          x1="313.479"
          y1="239.263"
          x2="340.648"
          y2="239.263"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F613B" />
          <stop offset="0.0430535" stopColor="#876A42" />
          <stop offset="0.1144" stopColor="#9D8455" />
          <stop offset="0.1224" stopColor="#A08757" />
          <stop offset="0.1603" stopColor="#AB9461" />
          <stop offset="0.2221" stopColor="#C5B278" />
          <stop offset="0.2857" stopColor="#D7C687" />
          <stop offset="0.3395" stopColor="#EADB97" />
          <stop offset="0.4454" stopColor="#E5D593" />
          <stop offset="0.5625" stopColor="#CFBC7F" />
          <stop offset="0.7336" stopColor="#B59D66" />
          <stop offset="0.8686" stopColor="#927546" />
          <stop offset="1" stopColor="#826337" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_185_1520"
          x1="339.233"
          y1="124.854"
          x2="487.803"
          y2="124.854"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F613B" />
          <stop offset="0.0430535" stopColor="#876A42" />
          <stop offset="0.1144" stopColor="#9D8455" />
          <stop offset="0.1224" stopColor="#A08757" />
          <stop offset="0.1603" stopColor="#AB9461" />
          <stop offset="0.2221" stopColor="#C5B278" />
          <stop offset="0.2857" stopColor="#D7C687" />
          <stop offset="0.3395" stopColor="#EADB97" />
          <stop offset="0.4454" stopColor="#E5D593" />
          <stop offset="0.5625" stopColor="#CFBC7F" />
          <stop offset="0.7336" stopColor="#B59D66" />
          <stop offset="0.8686" stopColor="#927546" />
          <stop offset="1" stopColor="#826337" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_185_1520"
          x1="246.922"
          y1="528.828"
          x2="282.138"
          y2="528.828"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F613B" />
          <stop offset="0.0430535" stopColor="#876A42" />
          <stop offset="0.1144" stopColor="#9D8455" />
          <stop offset="0.1224" stopColor="#A08757" />
          <stop offset="0.1603" stopColor="#AB9461" />
          <stop offset="0.2221" stopColor="#C5B278" />
          <stop offset="0.2857" stopColor="#D7C687" />
          <stop offset="0.3395" stopColor="#EADB97" />
          <stop offset="0.4454" stopColor="#E5D593" />
          <stop offset="0.5625" stopColor="#CFBC7F" />
          <stop offset="0.7336" stopColor="#B59D66" />
          <stop offset="0.8686" stopColor="#927546" />
          <stop offset="1" stopColor="#826337" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_185_1520"
          x1="144.59"
          y1="474.661"
          x2="219.425"
          y2="474.661"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F613B" />
          <stop offset="0.0430535" stopColor="#876A42" />
          <stop offset="0.1144" stopColor="#9D8455" />
          <stop offset="0.1224" stopColor="#A08757" />
          <stop offset="0.1603" stopColor="#AB9461" />
          <stop offset="0.2221" stopColor="#C5B278" />
          <stop offset="0.2857" stopColor="#D7C687" />
          <stop offset="0.3395" stopColor="#EADB97" />
          <stop offset="0.4454" stopColor="#E5D593" />
          <stop offset="0.5625" stopColor="#CFBC7F" />
          <stop offset="0.7336" stopColor="#B59D66" />
          <stop offset="0.8686" stopColor="#927546" />
          <stop offset="1" stopColor="#826337" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_185_1520"
          x1="312.943"
          y1="347.331"
          x2="321.197"
          y2="347.331"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F613B" />
          <stop offset="0.0430535" stopColor="#876A42" />
          <stop offset="0.1144" stopColor="#9D8455" />
          <stop offset="0.1224" stopColor="#A08757" />
          <stop offset="0.1603" stopColor="#AB9461" />
          <stop offset="0.2221" stopColor="#C5B278" />
          <stop offset="0.2857" stopColor="#D7C687" />
          <stop offset="0.3395" stopColor="#EADB97" />
          <stop offset="0.4454" stopColor="#E5D593" />
          <stop offset="0.5625" stopColor="#CFBC7F" />
          <stop offset="0.7336" stopColor="#B59D66" />
          <stop offset="0.8686" stopColor="#927546" />
          <stop offset="1" stopColor="#826337" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_185_1520"
          x1="341.538"
          y1="530.478"
          x2="355.844"
          y2="530.478"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7F613B" />
          <stop offset="0.0430535" stopColor="#876A42" />
          <stop offset="0.1144" stopColor="#9D8455" />
          <stop offset="0.1224" stopColor="#A08757" />
          <stop offset="0.1603" stopColor="#AB9461" />
          <stop offset="0.2221" stopColor="#C5B278" />
          <stop offset="0.2857" stopColor="#D7C687" />
          <stop offset="0.3395" stopColor="#EADB97" />
          <stop offset="0.4454" stopColor="#E5D593" />
          <stop offset="0.5625" stopColor="#CFBC7F" />
          <stop offset="0.7336" stopColor="#B59D66" />
          <stop offset="0.8686" stopColor="#927546" />
          <stop offset="1" stopColor="#826337" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Health;
