import React from "react";

const CircleTop = () => {
  return (
    <svg
      width="275"
      height="256"
      className={"CircleTop"}
      viewBox="0 0 275 256"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M184.803 250.973C159.553 259.389 131.441 255.368 102.189 240.807C72.9338 226.245 42.5727 201.157 12.8712 167.508C-46.5304 100.213 -103.227 -1.24568 -143.115 -120.92C-183.002 -240.594 -198.513 -355.78 -191.364 -445.257C-187.789 -489.996 -178.551 -528.283 -163.883 -557.485C-149.216 -586.684 -129.138 -606.767 -103.888 -615.183C-78.6388 -623.598 -50.5266 -619.577 -21.2742 -605.016C7.98083 -590.454 38.3419 -565.366 68.0434 -531.718C127.445 -464.422 184.142 -362.964 224.029 -243.289C263.917 -123.615 279.428 -8.42941 272.279 81.0473C268.704 125.787 259.466 164.073 244.797 193.275C230.13 222.474 210.052 242.558 184.803 250.973Z"
        stroke="#C48697"
      />
    </svg>
  );
};

export default CircleTop;
